/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section from '../components/section';
import ShapedImage from '../components/shaped-image';
import CategoriesList from '../components/categories-list';
import InternalLink from '../components/internal-link';

/**
 * Stylesheets
 */
import '../components/blog-post/style.scss';


const CategoryArchiveTemplate = ( props ) => {
    const {
        location,
        data,
        pageContext,
    } = props;

    const pageTitle = pageContext && pageContext.category !== 'Interviews' ? pageContext.category + ' Articles ' : pageContext.category;

    return (
        <Layout location={ location } noCta>
            <SEO
                title={ pageTitle }
                imageAlt={ pageTitle }
            />
            <Section>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <h1>{ pageTitle }</h1>
                            { data.allMarkdownRemark.edges
                            && data.allMarkdownRemark.edges.length > 0 && (
                                <div className="blog-post-list">
                                    { data.allMarkdownRemark.edges.map( ( edge ) => {
                                        const {
                                            node,
                                        } = edge;

                                        const {
                                            excerpt,
                                            fields,
                                            frontmatter,
                                        } = node;

                                        const {
                                            slug,
                                        } = fields;

                                        const {
                                            featured_image: featuredImage,
                                            title,
                                            date,
                                            custom_excerpt: customExcerpt,
                                        } = frontmatter;

                                        const {
                                            childImageSharp,
                                        } = featuredImage;

                                        return (
                                            <InternalLink
                                                className="blog-post"
                                                to={ slug }
                                                key={ slug }
                                            >
                                                { featuredImage
                                                && (
                                                    <div className="blog-post-image">
                                                        <ShapedImage
                                                            square
                                                            name={
                                                                childImageSharp.fluid.originalName
                                                            }
                                                            alt={ title }
                                                        />
                                                    </div>
                                                ) }
                                                <div className="blog-post-content">
                                                    <h3>
                                                        { title }
                                                    </h3>
                                                    <time>
                                                        { date }
                                                    </time>
                                                    <p>
                                                        { customExcerpt || excerpt }
                                                    </p>
                                                </div>
                                            </InternalLink>
                                        );
                                    } ) }
                                </div>
                            ) }
                        </div>
                        <div className="col-12 offset-lg-1 col-lg-3">
                            <h3>Categories</h3>
                            <CategoriesList />
                        </div>
                    </div>
                </div>
            </Section>
        </Layout>
    );
};

CategoryArchiveTemplate.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
    data: PropTypes.objectOf( PropTypes.any ),
};

CategoryArchiveTemplate.defaultProps = {
    location: {},
    data: {},
};

export default CategoryArchiveTemplate;

export const categoryQuery = graphql`
    query($category: String) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC }
            filter: { frontmatter: { categories: { in: [$category] } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 160)
                    fields {
                        slug
                    }
                    frontmatter {
                        date(formatString: "Do MMMM YYYY")
                        title
                        custom_excerpt
                        featured_image {
                            childImageSharp {
                                fluid {
                                    base64
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    originalImg
                                    originalName
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
